import React from "react";
import { ConfigProvider } from "antd";
import type { AppContext, AppProps } from "next/app";
import { EnvEnum } from "@constants/enum";
import theme from "@constants/themeConfig";
import { AuthInfoType, getAuthInfo } from "@utils/auth";
import { getEnv } from "@utils/environments";
import { FeatureToggle, getFeatureToggleMapping } from "@utils/featureToggle";
import "../src/styles/globals.scss";
import Providers from "./Providers";

interface PIMAppProps extends AppProps {
  customProp: {
    authInfo?: AuthInfoType;
    featureFlags?: Record<FeatureToggle, boolean>;
    env: EnvEnum;
  };
}

const AdminApp = ({ Component, pageProps, customProp }: PIMAppProps) => (
  <Providers
    appContext={{
      authInfo: customProp.authInfo,
      featureFlags: customProp.featureFlags,
      env: customProp.env,
    }}
  >
    <ConfigProvider theme={theme}>
      <Component {...pageProps} />
    </ConfigProvider>
  </Providers>
);

AdminApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  const authInfo = getAuthInfo(ctx);
  const env = getEnv();
  const featureFlags = getFeatureToggleMapping(ctx, { env });
  const customProp = {
    authInfo,
    featureFlags,
    env,
  };
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return {
    customProp,
    pageProps,
  };
};

export default AdminApp;

import { EnvEnum } from "@constants/enum";

export const getEnv = (): EnvEnum =>
  (process.env.ENVIRONMENT as EnvEnum) || EnvEnum.DEV;

export const isBrowser = (): boolean => typeof window !== "undefined";

export const isServer = (): boolean => !isBrowser();

export const getClientEnv = () =>
  isBrowser()
    ? // eslint-disable-next-line
      (window as any)?.__ENV__?.env || EnvEnum.DEV
    : EnvEnum.DEV;

import { GetServerSidePropsContext, NextPageContext } from "next/types";
import { EnvEnum } from "@constants/enum";
import {
  FeatureToggle,
  FeatureToggleEnvType,
  featureToggleConfig,
} from "@featureFlags/featureFlags";
import { getCookie, setCookie } from "./cookie";

const COOKIE_PREFIX = "__feature-toggle_";

export { FeatureToggle };

export function isFeatureToggleEnabled(
  toggle: FeatureToggle,
  ctx: NextPageContext | GetServerSidePropsContext,
  env: EnvEnum
): boolean {
  const cookieKey = `${COOKIE_PREFIX}${toggle}`;

  const cookieValue = getCookie(cookieKey, ctx);

  if (cookieValue === undefined || cookieValue === null) {
    if (typeof featureToggleConfig[toggle] === "boolean") {
      return featureToggleConfig[toggle] as boolean;
    }

    return (featureToggleConfig[toggle] as FeatureToggleEnvType)[env];
  }

  return cookieValue === "true";
}

export function setFeatureToggleValueToCookie(
  toggle: FeatureToggle,
  value: boolean
) {
  const cookieKey = `${COOKIE_PREFIX}${toggle}`;

  setCookie(cookieKey, value.toString(), { httpOnly: false });
}

export function getFeatureToggleMapping(
  ctx: NextPageContext | GetServerSidePropsContext,
  { env }: { env: EnvEnum }
) {
  const featureToggleMapping: Partial<Record<FeatureToggle, boolean>> = {};
  const featureToggleList = Object.values(FeatureToggle);
  featureToggleList.forEach((toggle) => {
    featureToggleMapping[toggle] = isFeatureToggleEnabled(toggle, ctx, env);
  });

  return featureToggleMapping as Record<FeatureToggle, boolean>;
}

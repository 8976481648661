import type { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    colorPrimary: "#4142C9",
    colorError: "#FF5273",
    colorInfo: "#4142C9",
    colorLink: "#4142C9",
    colorSuccess: "#41CC4F",
    colorTextBase: "#111827",
    colorWarning: "#FFCF0D",
  },
};

export default theme;

export enum CurrencyEnum {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BOV = "BOV",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHE = "CHE",
  CHF = "CHF",
  CHW = "CHW",
  CLF = "CLF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  COU = "COU",
  CRC = "CRC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRU = "MRU",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MXV = "MXV",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLE = "SLE",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STN = "STN",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  USN = "USN",
  UYI = "UYI",
  UYU = "UYU",
  UYW = "UYW",
  UZS = "UZS",
  VED = "VED",
  VES = "VES",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XAG = "XAG",
  XAU = "XAU",
  XBA = "XBA",
  XBB = "XBB",
  XBC = "XBC",
  XBD = "XBD",
  XCD = "XCD",
  XDR = "XDR",
  XOF = "XOF",
  XPD = "XPD",
  XPF = "XPF",
  XPT = "XPT",
  XSU = "XSU",
  XTS = "XTS",
  XUA = "XUA",
  XXX = "XXX",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL",
}

// need to update
export enum PackagingTypeEnum {
  "Bag" = "BAG",
  "Bar" = "BAR",
  "Box" = "BOX",
  "Bottle" = "BTL",
  "Can" = "CAN",
  "Cup" = "CUP",
  "Jar" = "JAR",
  "PCH" = "PCH",
  "Pack" = "PCK",
  "SET" = "SET",
  "TBE" = "TBE",
  "TRY" = "TRY",
  "PVC jar" = "PVC_JAR",
  "PET bottle" = "PET_BOTTLE",
  "PE bag" = "PE_BAG",
  "Glass jar" = "GLASS_JAR",
}

export enum WhStorageEnum {
  "Ambient storage" = "AMBIENT_STORAGE",
  "Dry storage" = "DRY_STORAGE",
  "Refrigerated storage" = "REFRIGERATED_STORAGE",
  "Frozen Food storage" = "FROZEN_FOOD_STORAGE",
  "Hazardous storage" = "HAZARDOUS_STORAGE",
  "Archive storage" = "ARCHIVE_STORAGE",
  "Bonded storage" = "BONDED_STORAGE",
}

export enum WeightUnitEnum {
  KG = "KG",
  G = "G",
  LB = "LB",
  OZ = "OZ",
  ML = "ML",
  L = "L",
}

export enum LengthUnitEnum {
  CM = "CM",
}
export enum ShelfLifeUnitEnum {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum SellingPriceUomUnitEnum {
  ML = "ML",
  L = "L",
  G = "G",
  KG = "KG",
}

export enum NumberOfEmployeesEnum {
  FROM_1_TO_10 = "FROM_1_TO_10",
  FROM_10_TO_50 = "FROM_10_TO_50",
  FROM_50_TO_100 = "FROM_50_TO_100",
  MORE_THAN_100 = "MORE_THAN_100",
}

export enum AnnualSaleEnum {
  LOWER_THAN_1M = "LOWER_THAN_1M",
  FROM_1M_TO_5M = "FROM_1M_TO_5M",
  FROM_5M_TO_10M = "FROM_5M_TO_10M",
  MORE_THAN_10M = "MORE_THAN_10M",
}

export enum SupplierCountryCodeEnum {
  AR = "AR",
  AU = "AU",
  BR = "BR",
  CA = "CA",
  CN = "CN",
  HK = "HK",
  TW = "TW",
  CL = "CL",
  EG = "EG",
  DE = "DE",
  GR = "GR",
  ID = "ID",
  IN = "IN",
  JP = "JP",
  KR = "KR",
  MY = "MY",
  MX = "MX",
  MM = "MM",
  NZ = "NZ",
  PE = "PE",
  PH = "PH",
  RU = "RU",
  SG = "SG",
  ES = "ES",
  TH = "TH",
  TR = "TR",
  GB = "GB",
  US = "US",
  VN = "VN",
}

export enum SupplierDocumentCategoryEnum {
  CERTIFICATE = "CERTIFICATE",
  AWARD = "AWARD",
  CATALOG = "CATALOG",
  OTHER = "OTHER",
}

export enum SupplierCategory {
  FoodAndBeverage = "FOOD_AND_BEVERAGE",
  HomeAndLiving = "HOME_AND_LIVING",
}

export enum UserRole {
  OWNER = "OWNER",
  USER = "USER",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NO_LOGIN = "NO_LOGIN",
}

export enum AttributeGroupEnum {
  RECORD_INFO = "Record Info",
  BASIC_INFO = "Basic Info",
  PRICING = "Pricing",
  ITEM_PACKAGING = "Item Packaging",
  MARKETING = "Marketing",
  INVENTORY = "Inventory",
  COMPLIANCE = "Compliance",
  SHIPPING = "Shipping",
  GENERAL_SPECIFICATIONS = "General Specifications",
  FOOD_AND_BEVERAGE = "Food & Beverage",
  HOME_AND_LIVING = "Home & Living",
  BEAUTY_AND_PERSONAL_CARE = "Beauty & Personal Care",
  AMAZON = "Amazon",
  LAZADA = "Lazada",
  NTUC = "NTUC",
  SHOPEE = "Shopee",
  WOOCOMMERCE = "WooCommerce",
}

export enum AttributeClassEnum {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
  LIBRARY = "LIBRARY",
  AI = "AI",
  ALL = "ALL",
}

export enum AttributeTypeEnum {
  TEXT = "TEXT",
  DATE = "DATE",
  DATETIME = "DATETIME",
  DROPDOWN = "DROPDOWN",
  NUMBER = "NUMBER",
  PRICE = "PRICE",
  URL = "URL",
  MEASURE = "MEASURE",
  PRICE_MEASURE = "PRICE_MEASURE",
  RICH_TEXT = "RICH_TEXT",
  MULTI_SELECT = "MULTI_SELECT",
}

export enum SupportedDateFormatsEnum {
  "DD/MM/YYYY" = "DD/MM/YYYY",
  "MM/DD/YYYY" = "MM/DD/YYYY",
  "YYYY/MM/DD" = "YYYY/MM/DD",
}

export enum SupportedTimezonesEnum {
  "UTC +01:00" = "Africa/Algiers",
  "UTC +02:00" = "Europe/Paris",
  "UTC +03:00" = "Europe/Moscow",
  "UTC +04:00" = "Asia/Dubai",
  "UTC +05:00" = "Asia/Karachi",
  "UTC +06:00" = "Asia/Dhaka",
  "UTC +07:00" = "Asia/Jakarta",
  "UTC +08:00" = "Asia/Kuala_Lumpur",
  "UTC +09:00" = "Asia/Tokyo",
  "UTC +10:00" = "Australia/Brisbane",
  "UTC +11:00" = "Asia/Magadan",
  "UTC +12:00" = "Pacific/Auckland",
  "UTC +13:00" = "Pacific/Apia",
  "UTC +14:00" = "Pacific/Kiritimati",
  "UTC -01:00" = "Atlantic/Cape_Verde",
  "UTC -02:00" = "America/Noronha",
  "UTC -03:00" = "America/Recife",
  "UTC -04:00" = "America/Toronto",
  "UTC -05:00" = "America/Winnipeg",
  "UTC -06:00" = "America/Guatemala",
  "UTC -07:00" = "America/Los_Angeles",
  "UTC -08:00" = "America/Anchorage",
  "UTC -09:00" = "Pacific/Gambier",
  "UTC -10:00" = "Pacific/Honolulu",
  "UTC -11:00" = "Pacific/Pago_Pago",
}

export enum EnvEnum {
  DEV = "dev",
  QA = "qa",
  STAGING = "staging",
  PRODUCTION = "prod",
}

export const excludeAttributeTypeFromOptions = {
  [AttributeTypeEnum.PRICE]: true,
  [AttributeTypeEnum.MEASURE]: true,
  [AttributeTypeEnum.PRICE_MEASURE]: true,
};

export enum PlatformDescriptionKeyEnum {
  Shopee = "SHOPEE",
  Lazada = "LAZADA",
  Amazon = "AMAZON",
}

export enum CoreCategoryTree {
  GOOGLE_CATEGORY = "GOOGLE_CATEGORY",
  CUSTOM = "CUSTOM",
}

export enum AccountType {
  CUSTOMER = "CUSTOMER",
  TEST = "TEST",
}

export enum Tier {
  DEMO = "DEMO",
  USAGE_BASED = "USAGE_BASED",
  BASIC = "BASIC",
  PROFESSIONAL = "PROFESSIONAL",
  ENTERPRISE = "ENTERPRISE",
}

export enum CustomerSegment {
  RETAILER = "RETAILER",
  BRAND_OWNER = "BRAND_OWNER",
  MANUFACTURER = "MANUFACTURER",
  DISTRIBUTOR = "DISTRIBUTOR",
  ECOMMERCE_PLATFORM = "ECOMMERCE_PLATFORM",
  MARKETPLACE_PLATFORM = "MARKETPLACE_PLATFORM",
  CONSULTING_SERVICE_PROVIDER = "CONSULTING_SERVICE_PROVIDER",
  TECHNOLOGY_SERVICE_PROVIDER = "TECHNOLOGY_SERVICE_PROVIDER",
}

export enum SkuSegmentSizing {
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
  NOT_A_TARGET = "NOT_A_TARGET",
}

export enum ChannelEnum {
  MAGENTO = "MAGENTO",
  SHOPEE = "SHOPEE",
  AMAZON = "AMAZON",
  SHOPIFY = "SHOPIFY",
  LAZADA = "LAZADA",
  WOO = "WOO",
}

export enum PackageType {
  PIM_ONLY = "NO_PLAN",
  PIM_AND_CATALOG_ONLY = "CUSTOM_CATALOG_PLAN",
  ALL_FEATURES = "ALL_FEATURES_PLAN",
}

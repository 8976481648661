import React, { useMemo } from "react";
import { I18nextProvider } from "react-i18next";
import { ApolloProvider } from "@apollo/client/react";
import AppProvider, { AppContextType } from "@components/app-context";
import ApolloClientFactory from "@utils/apolloClient/ApolloClientFactory";
import i18n from "../src/i18n";

export interface ProvidersProps {
  appContext: AppContextType;
  children: React.ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({
  appContext,
  children,
}: ProvidersProps) => {
  const factory = useMemo(() => new ApolloClientFactory(), []);

  return (
    <AppProvider appContext={appContext}>
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={factory.client}>{children}</ApolloProvider>
      </I18nextProvider>
    </AppProvider>
  );
};

export default Providers;
